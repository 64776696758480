import PropTypes from 'prop-types';
import styled from 'styled-components';

import TaxiforsakringLogo from '../images/taxi_Taxiforsikring_Logo_org.png';

const LetterHead = ({ fields }) => {
  return (
    <PolicyLetterHead>
      <ContentContainer>
        <CustomerContainer>
          {fields.companyName}
          <br />
          {fields.companyAddress}
          <br />
          {fields.companyPostCode} {fields.companyCity}
          <br />
        </CustomerContainer>
        <LogoContainer>
          <TaxiforsikringLogoImg src={TaxiforsakringLogo} />
        </LogoContainer>
      </ContentContainer>
    </PolicyLetterHead>
  );
};

LetterHead.propTypes = {
  fields: PropTypes.shape({
    companyAddress: PropTypes.string.isRequired,
    companyCity: PropTypes.string.isRequired,
    companyName: PropTypes.string.isRequired,
    companyPostCode: PropTypes.string.isRequired,
  }).isRequired,
};

export default LetterHead;

const PolicyLetterHead = styled.header`
  @media print {
    width: 100%;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  @media print {
    padding-top: 11mm;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  margin-left: auto;
`;

const CustomerContainer = styled.div`
  line-height: 1.2;
  justify-contents: flex-end;

  @media print {
    font-size: 10pt;
  }
`;

const TaxiforsikringLogoImg = styled.img`
  width: 8rem;
  height: 4.36419rem;
  border: 0;

  @media print {
    width: 70pt;
    height: 37.17275pt;
  }
`;
