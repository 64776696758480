import styled from 'styled-components';

const LetterFoot = () => {
  return (
    <FooterContainer>
      <div>Telefon: 9090 8415</div>
      <div>E-post: post@forsikring.taxi</div>
      <div>Nettside: www.forsikring.taxi</div>
    </FooterContainer>
  );
};

export default LetterFoot;

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  page-break-after: always;

  @media print {
    width: 100%;
    font-size: 8pt;
  }
`;
