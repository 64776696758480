import PropTypes from 'prop-types';
import styled from 'styled-components';

const InsuredObject = ({ heading, answers }) => {
  return (
    <>
      <h2>{heading}</h2>
      <ContentGrid>
        <Content2ColGridRow>
          <Label>Løyvenummer</Label>
          <div>{answers.permitNumber}</div>
        </Content2ColGridRow>
        <Content2ColGridRow>
          <Label>Sentral</Label>
          <div>{answers.central}</div>
        </Content2ColGridRow>
        <Content2ColGridRow>
          <Label>Registreringsnummer</Label>
          <div>{answers.registrationNumber}</div>
        </Content2ColGridRow>
        <Content2ColGridRow>
          <Label>Bilmerke</Label>
          <div>{answers.carMake}</div>
        </Content2ColGridRow>
        <Content2ColGridRow>
          <Label>Årsmodell</Label>
          <div>{answers.carModelYear}</div>
        </Content2ColGridRow>
        <Content2ColGridRow>
          <Label>Kjørelengde</Label>
          <div>{answers.mileage}</div>
        </Content2ColGridRow>
        <Content2ColGridRow>
          <Label>Kjøres av</Label>
          <div>
            {answers.onlyDrivenByOwner === 'Ja'
              ? 'Løyvehaver'
              : 'Ingen begrensning'}
          </div>
        </Content2ColGridRow>
        <Content2ColGridRow>
          <Label>Antall seter</Label>
          <div>{answers.carNumberOfSeats} seter</div>
        </Content2ColGridRow>
      </ContentGrid>
    </>
  );
};

InsuredObject.propTypes = {
  answers: PropTypes.shape({
    carMake: PropTypes.string.isRequired,
    carModelYear: PropTypes.string.isRequired,
    carNumberOfSeats: PropTypes.string.isRequired,
    companyName: PropTypes.string.isRequired,
    mileage: PropTypes.string.isRequired,
    onlyDrivenByOwner: PropTypes.string.isRequired,
    permitNumber: PropTypes.string.isRequired,
    registrationNumber: PropTypes.string.isRequired,
  }).isRequired,

  heading: PropTypes.string.isRequired,
};

export default InsuredObject;

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 1rem;
  background-color: #fafafa;
  border-radius: 0.625rem;
  padding: 1rem;

  @media print {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
    font-size: 8pt;
    padding: 5pt;
  }
`;

const ContentGridRow = styled.div`
  display: grid;
  padding: 0.5rem 0.5rem;
  border-bottom: 0.0625rem solid #eaedee;

  &:first-child {
    border-top: 0.0625rem solid #eaedee;
  }

  &:nth-child(even) {
  }

  &:nth-child(odd) {
  }

  @media print {
    padding: 5pt;
    border-bottom: 1pt solid #eaedee;
  }
`;

const Content2ColGridRow = styled(ContentGridRow)`
  grid-template-columns: 1fr 2.4fr;
`;

const Label = styled.span`
  font-weight: 700;
  color: #6b7982;
`;
