import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import { configureAmplify } from '@eir/aws-authorization';
import { NotoSansGlobalFonts, PoppinsGlobalFonts } from '@eir/fonts';

import GlobalStyle from './styles/globalStyle';
import PolicyLetterView from './views/PolicyLetter/PolicyLetterView';
import QuoteLetterView from './views/QuoteLetter/QuoteLetterView';

configureAmplify(
  {
    endpointName: 'KrakenAPI',
    endpointUrl: process.env.REACT_APP_AWS_ENDPOINT_URL,
  },
  true
);

const App = () => {
  return (
    <Router>
      <NotoSansGlobalFonts />
      <PoppinsGlobalFonts />
      <GlobalStyle />
      <Switch>
        <Route exact path='/p/:policyId' component={PolicyLetterView} />
        <Route exact path='/q/:quoteId' component={QuoteLetterView} />
        <Redirect from='/:policyId' to='/p/:policyId' />
      </Switch>
    </Router>
  );
};

export default App;
