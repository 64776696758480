import styled from 'styled-components';
import { Icon } from '@eir/ui';

const PrintButton = () => {
  const handleClick = () => window.print();

  return (
    <Button onClick={handleClick}>
      <Icon iconName='faFilePdf' />
    </Button>
  );
};

export default PrintButton;

const Button = styled.button`
  border: 0;
  background-color: #0086c5;
  color: #fff;
  font-size: 2rem;
  border-radius: 50%;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  height: 5rem;
  width: 5rem;
  box-shadow: rgba(0, 0, 0, 0.4) 0 0.25rem 1rem;
  cursor: pointer;
  opacity: 0.7;
  transition: all 0.3s;

  &:hover {
    background-color: #006699;
    opacity: 1;
  }

  @media print {
    display: none;
  }
`;
