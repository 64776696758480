import PropTypes from 'prop-types';
import styled from 'styled-components';

const PaymentPlan = ({ ledgerData }) => {
  const formatDate = date => {
    const d = new Date(date);

    return d.toLocaleString('no-NO', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    });
  };

  return (
    <ContentGrid>
      <CaptionRow>
        <div>Periode</div>
        <RightAlignText>Beløp</RightAlignText>
      </CaptionRow>
      {ledgerData.map((item, index) => (
        <Row key={index}>
          <div>
            {formatDate(item.periodStart)} - {formatDate(item.periodEnd)}
          </div>
          <RightAlignText>
            kr{' '}
            {item.amount.toLocaleString('no-NO', {
              maximumFractionDigits: 0,
            })}
          </RightAlignText>
        </Row>
      ))}
    </ContentGrid>
  );
};

PaymentPlan.propTypes = {
  ledgerData: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number.isRequired,
      collected: PropTypes.bool.isRequired,
      collectionDay: PropTypes.string.isRequired,
      index: PropTypes.number.isRequired,
      periodEnd: PropTypes.string.isRequired,
      periodStart: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

export default PaymentPlan;

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 1rem;
  background-color: #fafafa;
  border-radius: 0.625rem;
  padding: 1rem;

  @media print {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
    padding: 5pt;
    font-size: 8pt;
  }
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 0.5rem 0.5rem;
  border-bottom: 0.0625rem solid #eaedee;

  @media print {
    padding: 5pt;
  }
`;

const CaptionRow = styled(Row)`
  color: #384954;
  font-size: 1.2rem;

  @media print {
    font-size: 12pt;
  }
`;

const RightAlignText = styled.div`
  text-align: right;
`;
