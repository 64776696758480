import PropType from 'prop-types';
import styled from 'styled-components';

const Cover = ({ type }) => {
  const Covers = () => {
    switch (type) {
      case 'ansvar':
        return (
          <>
            <Row>
              <div>Personskade</div>
              <div>Ubegrenset</div>
              <div>Ingen egenandel</div>
            </Row>
            <Row>
              <div>Tingskade</div>
              <div>Kr 100 000 000</div>
              <div>Ingen egenandel</div>
            </Row>
            <Row>
              <div>Bedriftsansvar</div>
              <div>Kr 10 000 000</div>
              <div>Kr 10 000</div>
            </Row>
            <Row>
              <div>Godsansvar</div>
              <div>I hht lov om Vegfraktavtaler</div>
              <div>Ingen egenandel</div>
            </Row>
            <Row>
              <div>Rettshjelp</div>
              <div>Kr 100 000</div>
              <div>Kr 4 000 + 20 % av overskytende</div>
            </Row>
          </>
        );
      case 'delkasko':
        return (
          <>
            <Row>
              <div>Brann</div>
              <div></div>
              <div>Kr 10 000</div>
            </Row>
            <Row>
              <div>Tyveri</div>
              <div></div>
              <div>Kr 10 000 – Hvis alarm; Se vilkår</div>
            </Row>
            <Row>
              <div>Ran og overfall</div>
              <div>Kr 25 000</div>
              <div>Ingen egenandel</div>
            </Row>
            <Row>
              <div>Fastmontert utstyr</div>
              <div>Kr 100 000</div>
              <div></div>
            </Row>
            <Row>
              <div>Bagasje</div>
              <div>Kr 10 000</div>
              <div></div>
            </Row>
          </>
        );
      case 'kasko':
        return (
          <>
            <Row>
              <div>Annen kaskoskade</div>
              <div></div>
              <div>Kr 20 000 ved første skade*</div>
            </Row>
          </>
        );
      case 'super':
        return (
          <>
            <Row>
              <div>Maskinskade</div>
              <div></div>
              <div>Se vilkår</div>
            </Row>
            <Row>
              <div>Avsavn/Driftstap</div>
              <div>Inntil kr 3 500 pr dag</div>
              <div>3 karensdager - Se vilkår</div>
            </Row>
            <Row>
              <div>Avsavn/Driftstap Garanti</div>
              <div>Inntil kr 3 500 pr dag </div>
              <div>10 karensdager - Se vilkår</div>
            </Row>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <ContentGrid>
      <CaptionRow>
        <div>Dekning</div>
        <div>Forsikringssum</div>
        <div>Egenandel</div>
      </CaptionRow>
      <Covers />
    </ContentGrid>
  );
};

Cover.propTypes = {
  type: PropType.string.isRequired,
};

export default Cover;

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 1rem;
  background-color: #fafafa;
  border-radius: 0.625rem;
  padding: 1rem;
  line-height: 1;

  @media print {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
    font-size: 8pt;
    padding: 5pt;
  }
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 0.5rem 0.5rem;
  border-bottom: 0.0625rem solid #eaedee;

  @media print {
    padding: 5pt;
  }
`;

const CaptionRow = styled(Row)`
  color: #384954;
  font-size: 1.2rem;

  @media print {
    font-size: 11pt;
  }
`;
