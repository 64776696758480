import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
* {
  outline: none;
  box-sizing: border-box;
  margin: 0;
}

@page {
  size: A4;
  margin: 6.35mm 6.35mm 14.46mm 6.35mm !important;
  
} 
`;

export default GlobalStyle;
