import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

import { Page } from '@eir/letter';
import { getOsaPolicyLetterService, useApiRequest } from '@eir/services';

import Cover from '../../components/Cover';
import CoverAndPremiumSummary from '../../components/CoverAndPremiumSummary';
import Information from '../../components/Information';
import InsuredObject from '../../components/InsuredObject';
import LetterFoot from '../../components/LetterFoot';
import LetterHead from '../../components/LetterHead';
import PolicyOverview from '../../components/PolicyOverview';
import PrintButton from '../../components/PrintButton';
import SafetyRegulationsPart1 from '../../components/SafetyRegulationsPart1';
import SafetyRegulationsPart2 from '../../components/SafetyRegulationsPart2';
import InsuranceContract from './components/InsuranceContract';
import PaymentPlan from './components/PaymentPlan';

const PolicyLetterView = () => {
  document.title = 'Forsikringsavtale';

  const match = useRouteMatch('/p/:policyId');
  const { data, error } = useApiRequest(
    getOsaPolicyLetterService('KrakenAPI', match.params.policyId)
  );

  if (error) console.log(error);

  return (
    data && (
      <>
        <PolicyLetterContainer>
          <Page
            pageHeader={<LetterHead fields={data.fields} />}
            pageFooter={<LetterFoot />}
            content={
              <ContentContainer>
                <InsuranceContract data={data} />
              </ContentContainer>
            }
          />
          <Page
            pageHeader={<LetterHead fields={data.fields} />}
            pageFooter={<LetterFoot />}
            content={
              <ContentContainer>
                <h1>Forsikringsbevis</h1>
                <PolicyOverview changePeriod={true} data={data} />
                <InsuredObject
                  heading='Hva er forsikret'
                  answers={data.fields}
                />
                <CoverAndPremiumSummary
                  coverLevel={data.coverLevel}
                  premiums={data.premiumTotals}
                  period={data.activePeriod}
                />
              </ContentContainer>
            }
          />
          <Page
            pageHeader={<LetterHead fields={data.fields} />}
            pageFooter={<LetterFoot />}
            content={
              <>
                <ContentContainer>
                  <h3>Ansvar</h3>
                  <Cover type='ansvar' />
                  {(data.coverLevel === 'Delkasko' ||
                    data.coverLevel === 'Kasko' ||
                    data.coverLevel === 'Super') && (
                    <>
                      <h3>Delkasko</h3>
                      <Cover type='delkasko' />
                    </>
                  )}
                  {(data.coverLevel === 'Kasko' ||
                    data.coverLevel === 'Super') && (
                    <>
                      <h3>Kasko</h3>
                      <Cover type='kasko' />
                    </>
                  )}
                  {data.coverLevel === 'Super' && (
                    <>
                      <h3>Super</h3>
                      <Cover type='super' />
                    </>
                  )}
                </ContentContainer>
                {(data.coverLevel === 'Kasko' ||
                  data.coverLevel === 'Super') && (
                  <Disclaimer>
                    * Egenandelen er kr 20 000 ved første skade. Ved neste skade
                    innenfor en 12 måneders periode er egenandelen kr 40 000 og
                    ved tredje- og påfølgende skader innenfor en 12 måneders
                    periode <NoWrap>kr 60 000.</NoWrap>
                  </Disclaimer>
                )}
                <br />
                <Strong>
                  Glass og Redning er ikke omfattet av forsikringen.
                </Strong>
              </>
            }
          />
          <Page
            pageHeader={<LetterHead fields={data.fields} />}
            pageFooter={<LetterFoot />}
            content={
              <ContentContainer>
                <h1>Betalingsplan</h1>
                <PaymentPlan ledgerData={data.paymentSchedule.schedule} />
              </ContentContainer>
            }
          />
          <Page
            pageHeader={<LetterHead fields={data.fields} />}
            pageFooter={<LetterFoot />}
            content={
              <ContentContainer>
                <h1>Generell informasjon</h1>
                <Information />
              </ContentContainer>
            }
          />
          <Page
            pageHeader={<LetterHead fields={data.fields} />}
            pageFooter={<LetterFoot />}
            content={
              <ContentContainer>
                <h1>Sikkerhetsforskrifter</h1>
                <SafetyRegulationsPart1 />
              </ContentContainer>
            }
          />
          <Page
            pageHeader={<LetterHead fields={data.fields} />}
            pageFooter={<LetterFoot />}
            content={
              <ContentContainer>
                <SafetyRegulationsPart2 />
              </ContentContainer>
            }
          />
        </PolicyLetterContainer>
        <PrintButton />
      </>
    )
  );
};

export default PolicyLetterView;

const PolicyLetterContainer = styled.div`
  font-family: Poppins;
  width: 56.25rem;
  margin: 1.875rem auto;
  color: #7b7d8c;
  line-height: 1.7;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;

  @media print {
    margin: 0;
    width: 100%;
  }

  h1 {
    font-weight: 700;
    font-size: 1.6rem;
    color: #32346a;
    background-color: #df492c;
    padding: 0 0.5rem;
    margin: 0.5rem 0;

    @media print {
      -webkit-print-color-adjust: exact !important;
      color-adjust: exact !important;
      font-size: 14pt;
    }
  }

  h2 {
    color: #32346a;
    font-size: 1.2rem;

    @media print {
      font-size: 12pt;
    }
  }

  h3 {
    color: #32346a;
    font-size: 1rem;

    @media print {
      font-size: 10pt;
    }
  }

  p {
    line-height: 1.3;
    margin-bottom: 0.5rem;
    font-family: NotoSans;
    font-weight: 400;

    @media print {
      margin-bottom: 8pt;
      font-size: 10pt;
    }
  }
`;

const ContentContainer = styled.div`
  margin-top: 3rem;

  @media print {
    margin-top: 1cm;
  }
`;

const Disclaimer = styled.div`
  font-style: italic;
  font-size: 0.8rem;
`;

const NoWrap = styled.span`
  white-space: nowrap;
`;

const Strong = styled.p`
  font-weight: 700;
`;
