import PropTypes from 'prop-types';
import styled from 'styled-components';

import Taxi from '../../../images/taxi.jpg';

const QuotationContract = ({ data }) => {
  return (
    <InsuranceContractContainer>
      <h1>Forsikringstilbud {data.answers.registrationNumber}</h1>
      <p>
        Vedlagt følger ditt forsikringstilbud. I dette dokumentet finner du all
        nødvendig informasjon som vi ber deg lese nøye igjennom. Er noe uklart,
        utelatt eller feil må du melde fra så raskt som mulig.
      </p>
      <p>
        I tillegg til forsikringsavtalen gjelder:
        <UL>
          <li>Forsikringsbeviset</li>
          <li>Generelle vilkår</li>
          <li>Forsikringsvilkårene</li>
          <li>Sikkerhetsforskrifter</li>
          <li>
            Lov om forsikringsavtaler av 16. juni 1989 (FAL), Bilansvarsloven av
            3 februar 1961 (BAL)
          </li>
          <li>Øvrig lovverk</li>
        </UL>
      </p>
      <ImageContainer>
        <img src={Taxi} alt='Taxi' />
      </ImageContainer>
      <p>
        Forsikringsbeviset gjelder foran forsikringsvilkårene.
        Forsikringsvilkårene gjelder foran generelle vilkår. Forsikringsbeviset,
        forsikringsvilkårene og generelle vilkår gjelder foran lovbestemmelser
        som kan fravikes.
      </p>
    </InsuranceContractContainer>
  );
};

QuotationContract.propTypes = {
  data: PropTypes.object,
};

export default QuotationContract;

const InsuranceContractContainer = styled.div`
  margin-top: 7rem;

  @media print {
    margin-top: 3cm;
  }
`;

const ImageContainer = styled.div`
  display: flex;

  img {
    margin: 2rem auto;
    width: 30rem;
    height: 21.092rem;

    @media print {
      width: 12cm;
      height: 8.43674cm;
      margin: 2cm auto;
    }
  }
`;

const UL = styled.ul``;
